.home-container {
  video {
    user-select: none;
    background-color: #93745a;
  }

  .frame {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;

    .myVideo {
      min-width: 100%;
      min-height: 100%;
      filter: blur(10px);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.66);
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .reel {
    position: absolute;
    left: 75px;
    top: 96px;
    height: calc(100% - 210px);
    width: calc(100vw - 150px);
    z-index: 1;
    overflow: hidden;
    border-radius: 6px;

    .myVideo {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      top: 50%;
      border-radius: 6px;

      @media (max-width: 991.98px) {
        max-width: 100%;
        min-height: 100%;
        max-width: none;
      }
    }

    @media (max-width: 991.98px) {
      left: 32px;
      top: 67px;
      height: calc(100% - 142px);
      width: calc(100vw - 64px);
    }
    @media (max-width: 575.98px) {
      left: var(--side-padding);
      top: 67px;
      height: calc(100% - 142px);
      width: calc(100vw - (2 * var(--side-padding)));
    }
    @media (max-height: 474.98px) {
      left: var(--side-padding);
      top: 55px;
      height: calc(100% - 123px);
      width: calc(100vw - (2 * var(--side-padding)));
    }
  }

  @media (max-width: 1199.98px) {
  }
  @media (max-width: 991.98px) {
  }
  @media (max-width: 767.98px) {
  }
  @media (max-width: 575.98px) {
  }
}
