.about-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--darkblue);
  color: var(--white);
  top: 0;
  left: 0;
  transform: translateX(100%);
  transition: 0.3s ease-in-out;
  z-index: 5;
  padding: var(--side-padding) var(--side-padding) 150px;
  text-align: left;
  font-family: 'post_grotesk_light', sans-serif;

  @media (max-width: 767.98px) {
    padding: var(--side-padding) var(--side-padding) 99px;
  }
  @media (max-height: 474.98px) {
    padding: var(--side-padding) var(--side-padding) 73px;
  }

  .contact-title {
    font-family: 'gt_super', serif;
    font-size: 1.75rem;
    line-height: 2.025rem;
    margin: 0;
  }

  .top,
  .bottom {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (max-height: 474.98px) {
      gap: 16px;
    }
  }

  .top {
    a {
      margin-top: 2px;
    }
    margin-bottom: 40px;
    @media (max-width: 575.98px) {
      width: calc(100% - 40px);
    }

    @media (max-height: 474.98px) {
      margin-bottom: 20px;
      width: calc(100% - 40px);
    }
  }

  .bottom {
    font-size: 1.125rem;
    line-height: 1.49625rem;

    @media (max-width: 767.98px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      column-gap: 40px;
      row-gap: 20px;
    }

    @media (max-height: 474.98px) {
      flex-direction: row;
    }
  }

  .socials {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;

    p {
      margin: 0;
    }

    @media (max-width: 575.98px) {
      &:last-child {
        min-width: 100%;
      }
    }

    @media (max-height: 474.98px) {
      gap: 8px;
    }
  }

  .panel {
    width: 460px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;

    @media (max-width: 575.98px) {
      width: 100%;
    }

    @media (max-height: 474.98px) {
      width: 100%;
      overflow-y: auto;
    }

    a {
      color: var(--white);
      text-decoration: none;
      transition: 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &.active {
    transition: 0.3s ease-in-out;
    transform: translateX(0%);

    .panel {
      opacity: 1;
      transition: 0.3s 0.3s ease-in-out;
    }
  }
}
