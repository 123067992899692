header {
  width: 100%;
  position: absolute;
  top: 0;
  padding: var(--header-top) var(--side-padding);
  z-index: 9;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.25rem;

  .time {
    transition: 0.3s;
    opacity: 0;

    &.active {
      transition: 0.3s 0.3s;
      pointer-events: all;
      opacity: 1;
    }
  }

  nav {
    position: relative;
    button {
      position: absolute;
      background-color: transparent;
      color: var(--white);
      border: none;
      font-size: 1.25rem;
      right: 0;
      pointer-events: none;
      transition: 0.3s;
      opacity: 0;

      &.text {
        padding: 0;
        .line {
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: var(--white);
          transition: 0.2s;
          bottom: 0;
        }

        &:hover {
          .line {
            background-color: var(--pink);
          }
        }
      }

      &.close {
        height: 27px;
        width: 27px;
        padding: 0;

        .line {
          width: 32px;
          height: 1px;
          position: absolute;
          background-color: var(--white);
          left: 50%;
          top: 50%;
          transform-origin: center;
          transition: 0.2s;

          &.linetop {
            transform: rotateZ(0deg) translate3d(-50%, -50%, 0) rotateZ(45deg);
          }
          &.linebottom {
            transform: translate3d(-50%, -50%, 0) rotateZ(-45deg);
          }
        }

        &:hover {
          .line {
            width: 28px;
            height: 2px;
          }
        }
      }

      &.active {
        transition: 0.3s 0.3s;
        pointer-events: all;
        opacity: 1;
      }
    }
  }

  @media (max-width: 991.98px) {
  }

  @media (max-width: 767.98px) {
  }

  @media (max-width: 575.98px) {
  }
}
