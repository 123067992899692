.showreel-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--darkblue);
  color: var(--white);
  top: 0;
  left: 0;
  transform: translateX(100%);
  transition: 0.3s ease-in-out;
  z-index: 5;
  opacity: 0;
  padding: 83px var(--side-padding) 103px;
  text-align: left;
  font-family: 'post_grotesk_light', sans-serif;

  &.active {
    transition: 0s ease-in-out;
    transform: translateX(0%);
    opacity: 1;

    .panel {
      display: block;
    }
  }

  @media (max-width: 767.98px) {
    padding: 35px var(--side-padding) 73px;
  }
  @media (max-height: 474.98px) {
    padding: 35px var(--side-padding) 73px;
  }
  .panel {
    position: relative;
    height: 100%;
    width: 100%;
    display: none;

    iframe {
      height: 100%;
      width: 100%;
      border: none;
    }
  }
}
