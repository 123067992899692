:root {
  --pink: #fe1696;
  --darkblue: #17173f;
  --white: #fff;
  --black: #000000;
  --pink-light: rgba(234, 18, 139, 0.2);

  --side-padding: 40px;
  --header-top: 30px;
  --content-top: 40px;
}

@media (max-width: 767.98px) {
  :root {
    --side-padding: 20px;
    --header-top: 20px;
    --content-top: 20px;
  }
}

@media (max-height: 474.98px) {
  :root {
    --side-padding: 20px;
    --header-top: 20px;
    --content-top: 20px;
  }
}

@font-face {
  font-family: 'post_grotesk_light';
  src: url('./assets/fonts/postgrotesk-light-bf6466f03318977-webfont.woff2') format('woff2'),
    url('./assets/fonts/postgrotesk-light-bf6466f03318977-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'post_grotesk_medium';
  src: url('./assets/fonts/postgrotesk-medium-bf6466f03301dc2-webfont.woff2') format('woff2'),
    url('./assets/fonts/postgrotesk-medium-bf6466f03301dc2-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'gt_super';
  src: url('./assets/fonts/gt-super-display-regular-trial-webfont.woff2') format('woff2'),
    url('./assets/fonts/gt-super-display-regular-trial-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#root,
body,
html {
  font-size: 16px;
  height: 100%;
  font-family: 'post_grotesk_light', sans-serif;
  overflow: hidden;
}

@media (max-width: 767.98px) {
  #root,
  body,
  html {
    font-size: 14px;
  }
}
@media (max-height: 474.98px) {
  #root,
  body,
  html {
    font-size: 14px;
  }
}

.App {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  overflow: hidden;
  height: 100%;
}
header,
footer {
  flex: none;
}
.main {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

button {
  cursor: pointer;
}

p strong {
  font-family: 'post_grotesk_medium', sans-serif;
}

.g-button {
  height: 35px;
  font-size: 1.125rem;
  line-height: 1.125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  border-radius: 20px;
  text-decoration: none;
  gap: 8px;
  background-color: transparent;
  border: 1px solid var(--white);
  color: var(--white);

  transition: 0.2s;
}

@media (max-height: 474.98px) {
  .g-button {
    height: 30px;
  }
}

.g-button.pink {
  background-color: var(--pink);
  color: var(--white);
  border: none;
  box-shadow: 0 4px 6.3px #00000021;
  padding: 0 12px;
}

.g-button:hover {
  color: var(--darkblue);
  background-color: var(--white);
}

p {
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1.6625rem;
  font-style: normal;
}

.p-strong {
  font-family: 'post_grotesk_medium', sans-serif;
}

* {
  box-sizing: border-box;
}
