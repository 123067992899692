footer {
  width: 100%;
  position: absolute;
  padding: var(--header-top) var(--side-padding);

  bottom: 0;
  z-index: 9;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .graphicks-logo {
    svg {
      path {
        transition: 0.6s ease-in-out;
        fill: #fff;
      }
    }
  }

  .g-button {
    &.watsapp {
      background-color: transparent !important;
      border: 1px solid var(--white);
      svg {
        path {
          transition: 0.2s;
          fill: var(--white);
        }
      }
      &:hover {
        color: var(--white);
        border: 1px solid var(--pink);
      }
    }
  }

  @media (max-width: 767.98px) {
    svg {
      max-height: 35px;
      width: auto;
    }
  }

  @media (max-height: 474.98px) {
    svg {
      max-height: 30px;
      width: auto;
    }
  }

  &.about {
    svg {
      path {
        fill: var(--pink);
      }
    }
  }
}
